const calculateNewVal = (val, scalePercent) => {
  let newVal = val;
  newVal = val / 100 * scalePercent;
  newVal = newVal < 1 ? 1 : newVal;
  return newVal;
};
/**
 * Calculate the new value for an inline style value and respect the scaling options.
 */


function scaleMobile({
  enabled,
  scalePercent,
  scalePercentVertical
}, useWidthOfThisContainerId, original, replaceWith, additionalVerticalScaling = false) {
  if (process.env.PLUGIN_CTX === "pro") {
    /* Silence is golden... Until you have PRO Version! */
  }

  return original;
}

export { scaleMobile };