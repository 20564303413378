import { putHtmlCodeToDom } from "@devowl-wp/headless-content-unblocker";
import { getServiceSelection } from "../decision";
import { OPT_IN_EVENT } from "../events";

/**
 * Opt-in to a set of services.
 */
async function applyOptIn(options) {
  const allOptInServices = [];
  const {
    isManagerActive,
    iterateServices
  } = getServiceSelection(options);
  const {
    skipOptIn
  } = options; // Handle managers like GTM and MTM

  let isManagerOptOut = false;
  let dataLayerPromise = Promise.resolve(undefined);
  const waitPromises = []; // Handle opt-ins sequentially

  await iterateServices(async (group, service, isOptIn, {
    getDataLayer,
    serviceIsManager
  }) => {
    const {
      codeDynamics,
      codeOptIn,
      executeCodeOptInWhenNoTagManagerConsentIsGiven
    } = service;
    const isManager = isManagerActive && serviceIsManager;

    if (isOptIn) {
      // Check if the script should explicitly only be loaded if no consent is given to the manager
      const isOnlyWhenNoConsentToManager = isManagerActive && executeCodeOptInWhenNoTagManagerConsentIsGiven;
      const isSkipOptIn = typeof skipOptIn === "function" ? skipOptIn(service) : false;

      if (!isOnlyWhenNoConsentToManager && !isSkipOptIn) {
        waitPromises.push(putHtmlCodeToDom(codeOptIn, codeDynamics));
      } // If this is a manager, wait for the object to be loaded

      /* Silence is golden... Until you have PRO Version! */


      const summary = {
        group,
        service
      };
      document.dispatchEvent(new CustomEvent(OPT_IN_EVENT, {
        detail: summary
      }));
      allOptInServices.push(summary);
    } else if (isManager) {
      /* Silence is golden... Until you have PRO Version! */
    }
  });
  return {
    isManagerOptOut,
    dataLayerPromise,
    services: allOptInServices,
    ready: Promise.all(waitPromises)
  };
}

export { applyOptIn };